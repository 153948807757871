import React from "react";
import ContactBanner from "./ContactBanner"; 
import "./Contact.css";
import Instagram1 from "../../../Images/Instagram1.jpg"; 
import Instagram2 from "../../../Images/Instagram2.jpg"; 
import Instagram3 from "../../../Images/Instagram3.jpg"; 
import EmailPopup from "./EmailPopup";

interface IContactPageProps {
    addSubscriber: (email: string, successCb: () => void, failureCb: (msg: string) => void) => Promise<void>; 
}

interface IContactPageState {
    showModal: boolean; 
}

export default class Contact extends React.Component<IContactPageProps, IContactPageState> {
    constructor(props: Readonly<IContactPageProps>) {
        super(props); 

        this.state = {
            showModal: false 
        }
    }

    showPopup = () => {
        this.setState({
            showModal: true
        })
    }

    hidePopup = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        const { addSubscriber } = this.props; 
        const { showModal } = this.state; 

        return (
            <div className="main-content text-center">
                { showModal && (
                    <EmailPopup handleClose={this.hidePopup} addSubscriber={addSubscriber} />
                )}
                <ContactBanner />
                <div className="helvetica-light mt-15">
                    <span>WE WOULD LOVE FOR YOU TO <button className="inline-button large-font" onClick={() => {this.showPopup()}}>JOIN US</button>.<br />FOR ALL INQUIRIES EMAIL US AT <a href="mailto: info@shopgabriellamrose.com" className="email-link">INFO@SHOPGABRIELLAMROSE.COM</a></span>
                </div>
                <div className="flex-row mt-35 sample-images">
                    <img src={Instagram1} alt="Gabriella Rose in her custom design." className="responsive-image row-image" />
                    <img src={Instagram2} alt="Gabriella Rose in her custom design." className="responsive-image row-image" />
                    <img src={Instagram3} alt="Gabriella Rose in her custom design." className="responsive-image row-image" />
                </div>
                <div className="helvetica-light mt-35 dm-us">
                    <span>HAVE A QUICK QUESTION, BUT DON’T FEEL LIKE EMAILING?<br />DM US ON <a href="https://www.instagram.com/gabriellamrose/" className="inline-button large-font">INSTAGRAM</a></span>
                </div>
            </div>
        ); 
    }
}