import React from "react";
import IconLink from "../Common/IconLink";
import * as Icons from "@fortawesome/free-brands-svg-icons";

export class Footer extends React.Component<{}, {}> {
    render() {
        return (
            <footer className="pl-15 pb-15" id="footer">
                <div className="flex-row">
                    <IconLink 
                        href="https://www.instagram.com/gabriellamrose/" 
                        ariaLabel="Instagram link"
                        className="mr-15"
                        icon={Icons.faInstagram}
                    />
                    <IconLink 
                        href="https://www.pinterest.com/GabriellaMRose/" 
                        ariaLabel="Pinterest link"
                        className="mr-15"
                        icon={Icons.faPinterestP}
                    />
                </div>
            </footer>
        );
    }
}