import app from "firebase/app"; 
import "firebase/firestore";
import BlogPost from "../Model/BlogPost";

const firebaseConfig = {
    apiKey: "AIzaSyBtR6-_QNwFejzkfJ09cQwYbEZycYo_fmQ",
    authDomain: "gabriellaroseblog.firebaseapp.com",
    databaseURL: "https://gabriellaroseblog.firebaseio.com",
    projectId: "gabriellaroseblog",
    storageBucket: "gabriellaroseblog.appspot.com",
    messagingSenderId: "769797629598",
    appId: "1:769797629598:web:1dfbb61f901bd4387efd8f"
  };

export default class Firebase {
    db: app.firestore.Firestore;
    
    constructor() {
        app.initializeApp(firebaseConfig); 
        this.db = app.firestore(); 
    }

    getBlogPosts = async () => {
        let posts: BlogPost[] = [];

        await this.db.collection("posts")
        .orderBy("CreatedAt", "desc")
        .get()
        .then((snapshot: app.firestore.QuerySnapshot<app.firestore.DocumentData>) => {     
            snapshot.docs.forEach((doc: app.firestore.DocumentSnapshot) => {
                posts.push(new BlogPost(doc.id, doc.data()));
            })
        });

        return posts; 
    }

    getPost = async (id: string) => {
        let post = undefined; 

        await this.db.collection("posts")
        .doc(id)
        .get()
        .then((snapshot) => {     
            post = new BlogPost(snapshot.id, snapshot.data()); 
        });

        return post; 
    }

    addSubscriber = async (email: string, successCb: () => void, failureCb: (msg: string) => void) => {
        if (!this.validEmail(email)) {
            failureCb("Please enter a valid email.");
            return;
        }

        await this.db.collection("subscribers").doc(email).set({
            email
        }).then((res) => {
            successCb(); 
        }, (err) => {
            failureCb("We're sorry. We were not able to add your email to the subscription list."); 
        }).catch(err => failureCb("We're sorry. We were not able to add your email to the subscription list."));
    }

    private validEmail = (email: string) => {
        if (email == null || email.trim().length === 0) {
            return false;  
        }

        return /\S+@\S+\.\S+/.test(email); 
    }
}