import React from "react"; 
import ContactBannerImage from "../../../Images/ContactBanner.jpg"; 
import ImageBanner from "../../Common/ImageBanner";

export default function ContactBanner() {
    return (
        <ImageBanner backgroundImage={ContactBannerImage} backgroundImageAltText="Gabriella Rose in her custom design." textPosition="bottom-right">
            <span className="contact-banner-title">HELLO LOVE.</span>
        </ImageBanner>
    )
}