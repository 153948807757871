import React from "react"; 
import BlogHeader from "../../../Images/BlogHeader.jpg"; 
import ImageBanner from "../../Common/ImageBanner";
import FashionCategory from "../../../Images/FashionCategory.jpg"; 
import TravelCategory from "../../../Images/TravelCategory.jpg"; 
import WeddingCategory from "../../../Images/WeddingCategory.jpg"; 
import "./Blog.css";
import { Link } from "react-router-dom";
import EmailPopup from "../Contact/EmailPopup";

interface IBlogProps {
    addSubscriber: (email: string, successCb: () => void, failureCb: (msg: string) => void) => Promise<void>; 
}

interface IBlogState {
    showModal: boolean;
}

export default class Blog extends React.Component<IBlogProps, IBlogState> {
    constructor(props: Readonly<IBlogProps>) {
        super(props); 

        this.state = {
            showModal: false
        }
    }

    showPopup = () => {
        this.setState({
            showModal: true
        })
    }

    hidePopup = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        const { addSubscriber } = this.props; 
        const { showModal } = this.state; 

        return (
            <div className="main-content text-center">
                { showModal && (
                    <EmailPopup handleClose={this.hidePopup} addSubscriber={addSubscriber} />
                )}
                <ImageBanner backgroundImage={BlogHeader} backgroundImageAltText="White Roses" textPosition="bottom-right">
                    <span className="blog-banner-title">G's WORLD</span>
                </ImageBanner>
                <div className="helvetica-light mt-15">
                    <span>WELCOME TO <span className="large-font tenez">G's WORLD,</span> YOUR EVERBLOOMING SOURCE FOR ALL THINGS FASHION, WEDDING, & TRAVEL</span>
                </div>
                <div className="flex-row mt-35 blog-categories">
                    <div className="category">
                        <ImageBanner backgroundImage={FashionCategory} backgroundImageAltText="Gabriella Rose bracelet" textPosition="center">
                            <div className="category-link-container">
                                <Link className="inline-button bold" to="/G'sWorld/Fashion">FASHION</Link>
                            </div>
                        </ImageBanner>
                    </div>
                    <div className="category">
                        <ImageBanner backgroundImage={WeddingCategory} backgroundImageAltText="White wedding dresses." textPosition="center">
                            <div className="category-link-container">
                                <Link className="inline-button bold" to="/G'sWorld/Wedding">WEDDING</Link>
                            </div>
                        </ImageBanner>
                    </div>
                    <div className="category">
                        <ImageBanner backgroundImage={TravelCategory} backgroundImageAltText="Eifell Tower." textPosition="center">
                            <div className="category-link-container">
                                <Link className="inline-button bold" to="/G'sWorld/Travel">TRAVEL</Link>
                            </div>
                        </ImageBanner>
                    </div>
                </div>
                <div className="helvetica-light mt-35 dm-us">
                    <span>FOR THE LATEST UPDATES ON NEW POSTS, COLLECTIONS, AND MUCH MORE, <button className="inline-button large-font" onClick={() => {this.showPopup()}}>JOIN US</button></span>
                </div>
            </div>
        );
    }
}