import React from "react"; 
import BlogPost from "../../../../Model/BlogPost";
import ImageBanner from "../../../Common/ImageBanner";
import "./BlogList.css"; 
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../../../Common/LoadingSpinner";

interface IBlogListProps {
    getBlogPosts: () => Promise<BlogPost[]>; 
    title: string;
    subTitle: string;  
}

interface IBlogListState {
    blogPosts: BlogPost[]; 
    loading: boolean; 
}

export default class BlogList extends React.Component<IBlogListProps, IBlogListState> {
    _isMounted = false; 

    constructor(props: Readonly<IBlogListProps>) {
        super(props); 

        this._isMounted = false; 

        this.state = {
            blogPosts: [],
            loading: false
        }
    }

    async componentDidMount() {
        const { getBlogPosts } = this.props; 
        
        this._isMounted = true; 

        this.setState({ loading: true }); 

        const blogPosts = this._isMounted && await getBlogPosts().finally(() => this.setState({ loading: false })); 

        this._isMounted && this.setState({
            blogPosts
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { blogPosts, loading } = this.state;   
        const { title, subTitle } = this.props; 

        return (
            <div className="standard-top-margin main-content text-center">
                <div className="helvetica blog-name">G's WORLD</div>
                <div className="xlarge-font bold">
                    {title}
                </div>
                <div className="helvetica">
                    {subTitle}
                </div>
                <div className="flex-row flex-wrap standard-top-margin blog-list">
                    { loading && (
                        <LoadingSpinner />
                    )}
                    { blogPosts.map(post => <ImageBanner 
                    key={post.Id} 
                    backgroundImage={post.CoverImageUrl} 
                    textPosition="bottom-left" 
                    imageClassName="blog-image" 
                    textClassName="link-text"
                    containerClassName={"blog-post-card"}>
                        <Link to={`${post.Type}/${post.Id}`} className="inline-button large-font bold italic overlay">{post.Title}</Link>
                    </ImageBanner>)}
                </div>
            </div>
        ); 
    }
}