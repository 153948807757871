import React from "react";
import "./Home.css";

export default class Home extends React.Component<{}, {}> {
    render() {
        return (
            <div role="main" className="main-content">
                <div role="img" aria-label="Gabriella Rose in her custom design." className="home-image" />
            </div>
        );
    }
}