import React from 'react';
import './App.css';
import { FirebaseContext } from './Firebase';
import { Route, Switch } from 'react-router-dom';
import Home from './Components/Pages/Home/Home';
import Collections, { CollectionNames } from './Components/Pages/Collections/Collections';
import MediterraneanShop from './Components/Pages/Shop/Mediterranean/MediterraneanShop';
import Contact from './Components/Pages/Contact/Contact';
import { MediterraneanCollection } from './Components/Pages/Collections/Mediterranean/MediterraneanCollection';
import CapriSlideshow from './Components/Pages/Shop/Mediterranean/CapriSlideshow';
import CapriDiscover from './Components/Pages/Collections/Mediterranean/DiscoverPages/CapriDiscover';
import Blog from './Components/Pages/Blog/Blog';
import BlogPost, { PostType } from './Model/BlogPost';
import BlogList from './Components/Pages/Blog/BlogLists/BlogList';
import BlogPostView from './Components/Pages/Blog/BlogPost/BlogPostView';

interface IAppState {
  blogPosts: BlogPost[]; 
}

class App extends React.Component<{}, IAppState> {
  constructor(props: Readonly<{}>) {
    super(props);
    
    this.state = {
      blogPosts: []
    }
  }

  getBlogPosts = async (firebase: any, postType: PostType) => {
    if (this.state.blogPosts.length === 0) {
      const blogPosts = await firebase.getBlogPosts(); 

      this.setState({
        blogPosts
      });
    }

    return this.state.blogPosts.filter(post => post.Type === postType); 
  }

  render() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => {
          return (
            <Switch>
              <Route exact path="/" render={() => <Home />} />
              <Route exact path="/Collections" render={() => <Collections />} />
              <Route exact path="/Contact" render={() => <Contact addSubscriber={firebase.addSubscriber}/>} />
              <Route exact path={`/G'sWorld`} render={() => <Blog addSubscriber={firebase.addSubscriber}/>} />
              <Route exact path={"/G'sWorld/Fashion"} render={() => <BlogList getBlogPosts={() => this.getBlogPosts(firebase, "Fashion")} title="FASHION" subTitle="YOUR EVERBLOOMING SOURCE FOR ALL THINGS FASHION"/>} />
              <Route exact path={"/G'sWorld/Travel"} render={() => <BlogList getBlogPosts={() => this.getBlogPosts(firebase, "Travel")} title="TRAVEL" subTitle="YOUR EVERBLOOMING SOURCE FOR ALL THINGS TRAVEL"/>} />
              <Route exact path={"/G'sWorld/Wedding"} render={() => <BlogList getBlogPosts={() => this.getBlogPosts(firebase, "Wedding")} title="WEDDING" subTitle="YOUR EVERBLOOMING SOURCE FOR ALL THINGS WEDDING"/>} />
              { this.state.blogPosts.length === 0 && (
                <Switch>
                  <Route exact path={"/G'sWorld/Fashion/:id"} render={(props) => <BlogPostView getPost={firebase.getPost} {...props}/>} />
                  <Route exact path={"/G'sWorld/Wedding/:id"} render={(props) => <BlogPostView getPost={firebase.getPost} {...props}/>} />
                  <Route exact path={"/G'sWorld/Travel/:id"} render={(props) => <BlogPostView getPost={firebase.getPost} {...props}/>} />              
                </Switch>
              )}
              { this.state.blogPosts.length !== 0 && (
                this.state.blogPosts.map(post => {
                  return <Route key={post.Id} exact path={`/G'sWorld/${post.Type}/${post.Id}`} render={() => <BlogPostView post={post} />} />
                })
              )}
              <Route render={() => <h1>404 not found</h1>} />
            </Switch>
          );
        }}
      </FirebaseContext.Consumer>
    );
  }
}

export default App;
