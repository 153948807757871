import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Firebase, { FirebaseContext } from './Firebase';
import { BrowserRouter } from "react-router-dom"; 
import NavBar from './Components/Navigation/NavBar';
import Banner from './Components/Banner/Banner';
import { Footer } from './Components/Footer/Footer';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <FirebaseContext.Provider value={new Firebase()}>
        <Banner />
        <div className="app-container">
          <div className="flex-row main">
            <NavBar />
            <App />
          </div>
          <Footer />
        </div>
      </FirebaseContext.Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
