import moment from "moment";
import app from "firebase/app"; 

export type PostType = "Fashion" | "Wedding" | "Travel"; 

export default class BlogPost {
    CreatedAt?: moment.Moment; 
    PostContent: string; 
    Type?: PostType; 
    Id: string; 
    Title: string; 
    CoverImageUrl: string; 

    constructor(id: string, data?: app.firestore.DocumentData) {
        this.CreatedAt = data?.CreatedAt ? moment(data.CreatedAt) : undefined;
        this.PostContent = data?.PostContent || ""; 
        this.Type = data?.Type || undefined;  
        this.Id = id;
        this.Title = data?.Title || "";
        this.CoverImageUrl = data?.CoverImage || "";   
    }   
}