import React from "react"; 
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

interface IIconButtonProps extends FontAwesomeIconProps {
    className?: string; 
    onClick: () => void; 
    ariaLabel: string; 
}

export default class IconButton extends React.Component<IIconButtonProps, {}> {
    render() {
        const { onClick, className, icon, ariaLabel } = this.props; 

        return (
            <button onClick={onClick} className={`inline-button ${className != null ? className : ""}`} aria-label={ariaLabel}><FontAwesomeIcon icon={icon} /></button>
        );
    }
}