import React from "react"; 
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

interface IIconLinkProps extends FontAwesomeIconProps {
    className?: string; 
    href: string; 
    ariaLabel: string; 
}

export default class IconLink extends React.Component<IIconLinkProps, {}> {
    render() {
        const { href, className, icon, ariaLabel } = this.props; 

        return (
            <a href={href} className={`inline-button ${className != null ? className : ""}`}><FontAwesomeIcon icon={icon} aria-label={ariaLabel}/></a>
        );
    }
}