import React from "react"; 
import "./Modal.css";
import ImageBanner from "./ImageBanner";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import IconButton from "./IconButton";

interface IModalProps {
    handleClose: () => void;
    backgroundImage?: string
}

export default class Modal extends React.Component<IModalProps, {}> {
    render() {
        const { handleClose, children, backgroundImage} = this.props;

        return (
            <div className="modal">
                {
                    !backgroundImage ? (
                        <section className="modal-main">
                            <button onClick={handleClose}>close</button>
                            <div>
                                {children}
                            </div>
                        </section>
                    ) : (
                        <section className="modal-main">
                            <div className="bg-image-container">
                                <div className="bg-image">
                                    <div className="close-button">
                                        <IconButton
                                            ariaLabel="Close Modal"
                                            icon={Icons.faTimes}
                                            onClick={handleClose}
                                        />
                                    </div>
                                    <ImageBanner 
                                        backgroundImage={backgroundImage}
                                        textPosition="center"
                                    >
                                        {children}
                                    </ImageBanner>
                                </div>
                            </div>
                        </section>
                    )
                }
            </div>
        );
    }
}