import React from "react"; 
import "./ImageBanner.css"; 

type TextPosition = "bottom-right" | "bottom-left" | "center" | "right"; 

interface IImageBannerProps {
    containerClassName?: string; 
    backgroundImage: string; 
    mobileBackgroundImage?: string;
    backgroundImageAltText?: string;
    textPosition: TextPosition; 
    textClassName?: string;
    imageClassName?: string;   
}

export default class ImageBanner extends React.Component<IImageBannerProps, {}> {
    getClassName = () => {
        const { textPosition } = this.props; 

        switch (textPosition) {
            case "center":
                return "image-banner-text-center";
            case "bottom-right":
                return "image-banner-text-bottom-right";
            case "bottom-left":
                return "image-banner-text-bottom-left"; 
            case "right":
                return "image-banner-text-right";
        }
    }

    render() {
        const { backgroundImage, children, backgroundImageAltText, textClassName, mobileBackgroundImage, imageClassName, containerClassName } = this.props; 

        return (
            <div className={`image-banner ${containerClassName || ""}`}>
                <img src={backgroundImage} alt={backgroundImageAltText} className={`responsive-image ${mobileBackgroundImage ? "hidden-mobile" : ""}  ${imageClassName || ""}`} />
                {mobileBackgroundImage && (
                    <img src={mobileBackgroundImage} alt={backgroundImageAltText} className={`responsive-image visible-mobile`} />    
                )}
                <div className={`${this.getClassName()} ${textClassName || ""}`}>
                    {children} 
                </div>
            </div>
        );
    }
}