import React from "react"
import { Link } from "react-router-dom";
import "./NavBar.css"; 
import Logo from "../../Images/GLogo.png";
import { CollectionNames } from "../Pages/Collections/Collections";
import IconButton from "../Common/IconButton";
import * as Icons from "@fortawesome/free-solid-svg-icons";

enum Page {
    Home = 0,
    Collections = 1,
    Shop = 2,
    Blog = 3,
    Contact = 4,
    MediterraneanShop = 5 
}

interface INavBarState {
    activePage: Page;
    showShopSubnav: boolean;
    shopActive: boolean; 
    showMobileNav: boolean; 
}

export default class NavBar extends React.Component<{}, INavBarState> {
    constructor(props: {}) {
        super(props);

        const path = window.location.href;

        let activePage = Page.Home;
        let showShopSubnav = false; 


        if (path.includes("Collections")) {
            activePage = Page.Collections;
        } else if (path.includes("Shop")) {
            if (path.includes(CollectionNames.Mediterranean.collectionLink)) {
                showShopSubnav = true; 
                activePage = Page.MediterraneanShop;
            }
        } else if (path.includes("G'sWorld")) {
            activePage = Page.Blog;
        } else if (path.includes("Contact")) {
            activePage = Page.Contact; 
        }

        this.state = {
            activePage,
            showShopSubnav,
            shopActive: showShopSubnav,
            showMobileNav: false
        }
    }

    setActivePage = (page: Page, hideSubnav: boolean) => {
        this.setState({
            activePage: page,
            showShopSubnav: !hideSubnav,
            shopActive: !hideSubnav
        })       
    }

    toggleShopSubnav = () => {
        const { showShopSubnav } = this.state;
        const prevShowSubnav = showShopSubnav; 

        this.setState({
            showShopSubnav: !prevShowSubnav
        });
    }

    toggleNav = () => {
        const { showMobileNav } = this.state;

        this.setState({
            showMobileNav: !showMobileNav 
        });
    }

    render() {
        const { activePage, showShopSubnav, shopActive, showMobileNav } = this.state; 

        return (
            <div className="nav-bar">
                <div className="flex-row">
                    <img src={Logo} alt="Gabriella Rose Logo" className="g-logo" />
                    <div className="mobile-menu">
                        <IconButton icon={showMobileNav ? Icons.faTimes : Icons.faBars} ariaLabel="Open navigation menu" onClick={this.toggleNav} className="burger-menu" />
                        <div className="tagline">EQUALITY HURTS NO ONE</div>
                    </div>
                    <ul role="navigation" className={`main-nav ${showMobileNav ? "show" : ""}`}>
                        <li className={`${ activePage === Page.Home ? "nav-link active" : "nav-link"}`}><Link className="nav-item" to="/" onClick={() => this.setActivePage(Page.Home, true)}>HOME</Link></li>
                        <li className={`${ activePage === Page.Collections ? "nav-link active" : "nav-link"}`}><Link className="nav-item" to="/Collections" onClick={() => this.setActivePage(Page.Collections, true)}>COLLECTIONS</Link></li>
                        <li className={`${ activePage === Page.Blog ? "nav-link active" : "nav-link"}`}><Link className="nav-item" to="/G'sWorld" onClick={() => this.setActivePage(Page.Blog, true)}>G's WORLD</Link></li>
                        <li className={`${ activePage === Page.Contact ? "nav-link active" : "nav-link"}`}><Link className="nav-item" to="/Contact" onClick={() => this.setActivePage(Page.Contact, true)}>CONTACT</Link></li>
                    </ul>
                </div>
            </div>
        );
    }
}