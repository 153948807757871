import React from "react"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

export class LoadingSpinner extends React.Component<{}, {}> {
    render() {
        return (
            <FontAwesomeIcon icon={Icons.faSpinner} pulse aria-label="Loading spinner" />
        );
    }
}