import React from "react"
import { CollectionCard, CollectionLink } from "../../../Types/CollectionCard";
import CollectionCardBanner from "./Common/CollectionCardBanner";
import MediterraneanMetamorphosis from "../../../Images/Mediterranean.jpg";
import HomeImage from "../../../Images/HomeImage.jpg";

type Collection = {
    collectionName: string,
    collectionLink: CollectionLink
}

export class CollectionNames {
    static Mediterranean: Collection = { 
        collectionName: "COMING SOON",
        collectionLink: "#"
    }
}

export default class Collections extends React.Component<{}, {}> {
    collections: CollectionCard[] = [
        {
            image: MediterraneanMetamorphosis,
            mobileImage: HomeImage,
            collectionName: CollectionNames.Mediterranean.collectionName,
            collectionLink: CollectionNames.Mediterranean.collectionLink,
            imageAlt: "Gabriella Rose in her custom design."
        }
    ]

    render() {
        return (
            <div className="standard-top-margin main-content-large">
                { this.collections.map((collection, index) => {
                    return (
                        <CollectionCardBanner 
                            collection={collection} 
                            key={index} />
                    )
                })
                }
            </div>
        );
    }
}