import React from "react";
import BlogPost from "../../../../Model/BlogPost";
import "./BlogPostView.css"; 
import { LoadingSpinner } from "../../../Common/LoadingSpinner";

interface IBlogPostProps {
    post?: BlogPost,
    getPost?: (id: string) => Promise<BlogPost>; 
    match?: {
        params: {
            id: string; 
        }
    }
}

interface IBlogPostState {
    post?: BlogPost; 
    loading: boolean; 
}

export default class BlogPostView extends React.Component<IBlogPostProps, IBlogPostState> {
    _isMounted = false; 

    constructor(props: Readonly<IBlogPostProps>) {
        super(props); 

        this._isMounted = false; 

        this.state = {
            post: undefined,
            loading: false
        }
    }

    componentDidMount() {
        const { post, getPost } = this.props;

        this._isMounted = true; 

        this.setState({ loading: true }); 
        
        if (post == null) {
            this._isMounted && getPost && getPost(this.props.match!.params.id).then(blogPost =>  this._isMounted && this.setState({ post: blogPost, loading: false })); 
        } else {
            this._isMounted && this.setState({
                post,
                loading: false
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { post, loading } = this.state; 

        return (
            <div className="main-content standard-top-margin">
                { loading && (
                    <LoadingSpinner />
                )}
                <div className="text-center helvetica post-type">{post?.Type?.toUpperCase()}</div>
                <div className="large-font bold text-center">{post?.Title?.toUpperCase()}</div>
                <div className="ql-editor helvetica" dangerouslySetInnerHTML={{__html: post?.PostContent || "" }} />
            </div>
        );
    }
}