import React from "react"; 
import { CollectionCard } from "../../../../Types/CollectionCard";
import { Link } from "react-router-dom";
import ImageBanner from "../../../Common/ImageBanner";
import "./CollectionCardBanner.css";

interface ICollectionCardProps {
    collection: CollectionCard;
}

export default class CollectionCardBanner extends React.Component<ICollectionCardProps, {}> {
    render() {
        const { collection } = this.props;

        return (
            <ImageBanner backgroundImage={collection.image} mobileBackgroundImage={collection.mobileImage} backgroundImageAltText={collection.imageAlt} textPosition="bottom-left" textClassName="collection-banner-text">
                <Link className="banner-link" to={`/Collections/${collection.collectionLink}`}>
                    <div className="collection-title">{collection.collectionName}</div>
                </Link>
            </ImageBanner>
                
        );
    }
}