import React from "react"; 
import Modal from "../../Common/Modal";
import EmailPopupBg from "../../../Images/EmailPopupBg.jpg"; 
import "./EmailPopup.css"; 
import { LoadingSpinner } from "../../Common/LoadingSpinner";

interface IEmailPopupProps { 
    handleClose: () => void; 
    addSubscriber: (email: string, successCb: () => void, failureCb: (msg: string) => void) => Promise<void>; 
}

interface IEmailPopupState {
    email: string; 
    loading: boolean; 
    responseText?: string; 
}

export default class EmailPopup extends React.Component<IEmailPopupProps, IEmailPopupState> {
    constructor(props: Readonly<IEmailPopupProps>) {
        super(props); 

        this.state = {
            email: "",
            loading: false,
            responseText: undefined
        }
    }

    submitEmail = (email: string) => {
        const { addSubscriber } = this.props;

        this.setState({ loading: true});

        addSubscriber(email, this.onSubmitSuccess, this.onSubmitFailure).finally(() => {
            this.setState({ loading: false});
        });
    }

    onSubmitSuccess = () => {
        this.setState({
            responseText: "Welcome to G's World!"
        })
    }

    onSubmitFailure = (msg: string) => {
        this.setState({
            responseText: msg
        })
    }

    handleEmailChange = (email: string) => {
        this.setState({
            email
        });
    }

    render() {
        const { handleClose } = this.props; 
        const { email, loading, responseText } = this.state; 

        return (
            <Modal handleClose={handleClose} backgroundImage={EmailPopupBg}>
                <div>
                    <div className="join-us italic">JOIN US.</div>
                    <div className="flex-row align-center">
                        <div className="form-container">
                            <form className="flex-row email-form">
                                <input type="email" aria-label="Email Address"  placeholder="Enter Email Here." onChange={(e) => this.handleEmailChange(e.target.value)}/>
                                <button className="submit-button" onClick={(e) => {
                                    e.preventDefault(); 
                                    this.submitEmail(email);
                                }}>SUBMIT</button>
                            </form>
                        </div>
                        { loading && (
                            <LoadingSpinner />
                        )}
                    </div>
                </div>
                <div>
                    { responseText && (
                        <div className="helvetica submit-response">{ responseText }</div>
                    )}
                </div>
            </Modal>
        )
    }
}